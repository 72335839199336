// statusUtils.js
export const getStatusClass = (status) => {
    switch (status) {
      case "Выполнен":
        return "status-completed";
      case "В работе":
        return "status-in-progress";
      case "Записан/ожидание согласования":
        return "status-pending";
      default:
        return "";
    }
  };