import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchOrders } from "../../services/api";
import "./ManagerPage.css";

const ManagerPage = () => {
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = { name: "Менеджер Иван" };

  const handleLogout = () => {
    if (window.confirm("Вы действительно хотите выйти?")) {
      navigate("/");
    }
  };

  const handleAddOrder = () => {
    navigate("/manager/add-order"); // Переход на страницу добавления заказа
  };

  useEffect(() => {
    const loadOrders = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchOrders(0, 10); 
        const mappedOrders = data.content.map((order) => ({
          id: order.id,
          clientName: `${order.client.firstName} ${order.client.lastName}`,
          phone: order.client.username, 
          car: `${order.car.brand} ${order.car.model}`,
          licensePlate: order.car.licensePlate,
        }));
        setOrders(mappedOrders);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadOrders();
  }, []);

  return (
    <div className="manager-page">
      <header className="header">
        <div className="header-left" onClick={handleLogout}>
          {user.name}
        </div>
        <div className="header-right">
          <button onClick={handleAddOrder} className="add-order-button">
            ДОБАВИТЬ
          </button>
        </div>
      </header>

      <div className="orders-list">
        {loading ? (
          <p>Загрузка...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : orders.length > 0 ? (
          orders.map((order) => (
            <div key={order.id} className="order-preview">
              <p>
                <strong>ФИО:</strong> {order.clientName}
              </p>
              <p>
                <strong>Телефон:</strong> {order.phone}
              </p>
              <p>
                <strong>Авто:</strong> {order.car}
              </p>
              <p>
                <strong>Номер:</strong> {order.licensePlate}
              </p>
            </div>
          ))
        ) : (
          <p>Заказы отсутствуют</p>
        )}
      </div>
    </div>
  );
};

export default ManagerPage;