import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminPage.css";
import { getStatusClass } from "../../utils/statusUtils";
import { fetchUsersWithPagination, fetchCars } from "../../services/api";

const AdminPage = () => {
  const [activeTab, setActiveTab] = useState("clients");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFilter, setSearchFilter] = useState("fullName");
  const [clients, setClients] = useState([]);
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();

  const searchOptions = activeTab === "clients"
    ? [
        { value: "fullName", label: "По имени" },
        { value: "phone", label: "По телефону" },
        { value: "email", label: "По email" },
      ]
    : [
        { value: "brand", label: "По марке" },
        { value: "model", label: "По модели" },
        { value: "licensePlate", label: "По гос. номеру" },
        { value: "vin", label: "По VIN" },
        { value: "color", label: "По цвету" },
        { value: "year", label: "По году" },
        { value: "serviceStatus", label: "По статусу" },
      ];

  const matchSearch = (term, data, field) => {
    if (!term) return true;
    if (!field) return true;
    return data[field]?.toString().toLowerCase().includes(term.toLowerCase());
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      if (activeTab === "clients") {
        const data = await fetchUsersWithPagination(currentPage, pageSize);
        setClients(data.users);
        setTotalPages(data.totalPages);
      } else if (activeTab === "cars") {
        const data = await fetchCars();
        setCars(data);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [activeTab, currentPage, pageSize]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const filteredClients = clients.filter((client) =>
    matchSearch(searchTerm, client, searchFilter)
  );

  const filteredCars = cars.filter((car) =>
    matchSearch(searchTerm, car, searchFilter)
  );

  const handleCarClick = (carId) => {
    navigate(`/car/${carId}`);
  };

  const handleOpenAddUserPage = () => {
    // Переходим на страницу добавления пользователя
    navigate("/admin/add-user");
  };

  return (
    <div className="admin-page-container">
      <div className="tabs">
        <button
          className={activeTab === "clients" ? "active" : ""}
          onClick={() => {
            setActiveTab("clients");
            setSearchFilter("fullName");
            setCurrentPage(0);
          }}
        >
          Клиенты
        </button>
        <button
          className={activeTab === "cars" ? "active" : ""}
          onClick={() => {
            setActiveTab("cars");
            setSearchFilter("brand");
            setCurrentPage(0);
          }}
        >
          Автомобили
        </button>
      </div>

      {activeTab === "clients" && (
        <div style={{ marginBottom: "20px" }}>
          <button
            className="add-user-button"
            onClick={handleOpenAddUserPage}
            disabled={loading}
          >
            Создать пользователя
          </button>
        </div>
      )}

      <div className="search-container">
        <input
          type="text"
          className="search-bar"
          placeholder="Введите поисковый запрос"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="search-filter"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
        >
          {searchOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        <p>Загрузка...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <div className="list-container">
          {activeTab === "clients" &&
            (filteredClients.length > 0 ? (
              <ul className="list">
                {filteredClients.map((client) => (
                  <li key={client.id} className="list-item">
                    <div>
                      <p><strong>ФИО:</strong> {client.fullName}</p>
                      <p><strong>Email:</strong> {client.email || "Не указано"}</p>
                      <p><strong>Телефон:</strong> {client.phone || "Не указано"}</p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Клиенты не найдены.</p>
            ))}

          {activeTab === "cars" &&
            (filteredCars.length > 0 ? (
              <ul className="list">
                {filteredCars.map((car) => (
                  <li
                    key={car.id}
                    className="list-item"
                    onClick={() => handleCarClick(car.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                      <p><strong>Марка:</strong> {car.brand}</p>
                      <p><strong>Модель:</strong> {car.model}</p>
                      <p><strong>Гос.номер:</strong> {car.licensePlate}</p>
                      <p><strong>VIN:</strong> {car.vin}</p>
                      <p><strong>Цвет:</strong> {car.color}</p>
                      <p><strong>Год:</strong> {car.year}</p>
                      <p>
                        <strong>Статус:</strong>{" "}
                        <span className={`status-badge ${getStatusClass(car.serviceStatus)}`}>
                          {car.serviceStatus}
                        </span>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Автомобили не найдены.</p>
            ))}
        </div>
      )}

      {activeTab === "clients" && (
        <div className="pagination">
          <button
            disabled={currentPage === 0}
            onClick={() => setCurrentPage((prev) => prev - 1)}
          >
            Назад
          </button>
          <span>
            Страница {currentPage + 1} из {totalPages}
          </span>
          <button
            disabled={currentPage + 1 >= totalPages}
            onClick={() => setCurrentPage((prev) => prev + 1)}
          >
            Вперед
          </button>
        </div>
      )}
    </div>
  );
};

export default AdminPage;