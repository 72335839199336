import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./context/UserContext";
import LoginPage from "./pages/LoginPage/LoginPage";
import ReceptionistPage from "./pages/ManagerPage/ManagerPage";
import ClientPage from "./pages/ClientPage/ClientPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import AddUserPage from "./pages/AddUserPage/AddUserPage";
import CarPage from "./pages/CarPage/CarPage";
import AddOrderPage from "./pages/AddOrderPage/AddOrderPage";

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admin/add-user" element={<AddUserPage />} />
          <Route path="/" element={<LoginPage />} />
          <Route path="/manager" element={<ReceptionistPage />} />
          <Route path="/add-order" element={<AddOrderPage />} />
          <Route path="/client" element={<ClientPage />} />
          <Route path="/car/:carId" element={<CarPage />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;