import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../auth/authService";
import UserContext from "../../context/UserContext";
import "./LoginPage.css";
import eyeIcon from "../../assets/images/eye.png"; // Открытый глаз
import eyeSlashIcon from "../../assets/images/eye-slash.png"; // Закрытый глаз

const LoginPage = () => {
  const [credentials, setCredentials] = useState({ username: "", password: "" });
  const [error, setError] = useState(""); // Ошибка от сервера
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      await login(credentials.username, credentials.password);
      const user = { username: credentials.username, role: "admin" }; // Здесь роль можно получить от сервера
      setUser(user);

      switch (user.role) {
        case "admin":
          navigate("/admin");
          break;
        case "receptionist":
          navigate("/form");
          break;
        case "client":
          navigate("/client");
          break;
        default:
          throw new Error("Неизвестная роль пользователя");
      }
    } catch (err) {
      // Показываем реальную ошибку из сервера
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h1>Войти</h1>
        {error && <p className="error-message">{error}</p>}
        <input
          type="text"
          placeholder="Имя пользователя"
          name="username"
          value={credentials.username}
          onChange={handleChange}
          disabled={loading}
        />
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Пароль"
            name="password"
            value={credentials.password}
            onChange={handleChange}
            disabled={loading}
          />
          <button
            type="button"
            className="toggle-password-button"
            onClick={togglePasswordVisibility}
            disabled={loading}
          >
            <img
              src={showPassword ? eyeIcon : eyeSlashIcon}
              alt={showPassword ? "Скрыть пароль" : "Показать пароль"}
              className="eye-icon"
            />
          </button>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Вход..." : "Далее"}
        </button>
      </form>
    </div>
  );
};

export default LoginPage;