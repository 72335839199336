import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AddOrderPage.css";

const AddOrderPage = () => {
  const [formData, setFormData] = useState({
    clientName: "",
    car: "",
    mileage: "",
    reason: "",
    comment: "",
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCancel = () => {
    navigate(-1); // Возвращаемся обратно
  };

  const handleSave = () => {
    alert("Сохранение заказа..."); // Заглушка, потом добавим отправку данных
  };

  return (
    <div className="add-order-container">
      <h1>Добавление нового заказа</h1>

      <div className="form-field">
        <label>ФИО:</label>
        <div className="field-with-button">
          <input
            type="text"
            name="clientName"
            value={formData.clientName}
            onChange={handleInputChange}
          />
          <button className="inline-button">Добавить</button>
        </div>
      </div>

      <div className="form-field">
        <label>Авто:</label>
        <div className="field-with-button">
          <input
            type="text"
            name="car"
            value={formData.car}
            onChange={handleInputChange}
          />
          <button className="inline-button">Добавить</button>
        </div>
      </div>

      <div className="form-field">
        <label>Пробег:</label>
        <input
          type="text"
          name="mileage"
          value={formData.mileage}
          onChange={handleInputChange}
        />
      </div>

      <div className="form-field">
        <label>Причина обращения:</label>
        <textarea
          name="reason"
          value={formData.reason}
          onChange={handleInputChange}
        ></textarea>
      </div>

      <div className="form-field">
        <button className="file-upload-button">Добавить фото/видео</button>
      </div>

      <div className="form-field">
        <label>Комментарий:</label>
        <textarea
          name="comment"
          value={formData.comment}
          onChange={handleInputChange}
        ></textarea>
      </div>

      <div className="form-actions">
        <button className="cancel-button" onClick={handleCancel}>
          Отмена
        </button>
        <button className="save-button" onClick={handleSave}>
          Сохранить
        </button>
      </div>
    </div>
  );
};

export default AddOrderPage;