const API_BASE_URL = "https://jedi1150.com/api";

/**
 * Обработчик ошибок API.
 * @param {Response} response - Объект ответа от fetch.
 * @throws {Error} - Генерирует ошибку с сообщением, полученным от сервера или общим сообщением.
 */
const handleApiError = async (response) => {
  let errorMessage = "Произошла ошибка. Попробуйте снова.";
  try {
    const errorData = await response.json();
    errorMessage = errorData.message || errorMessage;
  } catch {
    errorMessage = response.statusText || errorMessage;
  }
  throw new Error(`Ошибка ${response.status}: ${errorMessage}`);
};

/**
 * Вход в систему
 * @param {string} username - Имя пользователя.
 * @param {string} password - Пароль.
 * @throws {Error} - Если авторизация не удалась.
 */
export const login = async (username, password) => {
  const response = await fetch(`${API_BASE_URL}/auth/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
    credentials: "include", // Включение передачи куки
  });

  if (!response.ok) {
    await handleApiError(response);
  }
};

/**
 * Выход из системы
 * @throws {Error} - Если запрос на выход не удался.
 */
export const logout = async () => {
  const response = await fetch(`${API_BASE_URL}/auth/logout`, {
    method: "POST",
    credentials: "include", // Включение передачи куки
  });

  if (!response.ok) {
    await handleApiError(response);
  }
};

/**
 * Проверка аутентификации пользователя
 * @returns {Promise<boolean>} - Возвращает true, если пользователь аутентифицирован.
 */
export const checkAuth = async () => {
  const response = await fetch(`${API_BASE_URL}/auth/check`, {
    method: "GET",
    credentials: "include", // Включение передачи куки
  });

  if (!response.ok) {
    if (response.status === 401) {
      return false; // Пользователь не аутентифицирован
    }
    await handleApiError(response); // Обработка других ошибок
  }

  return true;
};