import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUser } from "../../services/api";
import "./AddUserPage.css";

const AddUserPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    role: "user",
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, username, password } = formData;

    // Простая валидация
    if (!firstName.trim() || !lastName.trim() || !username.trim() || !password.trim()) {
      alert("Пожалуйста, заполните все поля!");
      return;
    }

    try {
      setLoading(true);
      await createUser(formData);
      navigate(-1); // Возвращаемся назад к AdminPage
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1); // Возврат без создания
  };

  return (
    <div className="add-user-page-container">
      <h1>Создать нового пользователя</h1>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="firstName"
          placeholder="Имя"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="lastName"
          placeholder="Фамилия"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
        <select name="role" value={formData.role} onChange={handleChange} required>
          <option value="user">User</option>
          <option value="admin">Admin</option>
          <option value="client">Client</option>
        </select>
        <input
          type="text"
          name="username"
          placeholder="Имя для входа"
          value={formData.username}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Пароль"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <div className="button-group">
          <button type="submit" disabled={loading}>Сохранить</button>
          <button type="button" onClick={handleCancel}>Отмена</button>
        </div>
      </form>
    </div>
  );
};

export default AddUserPage;