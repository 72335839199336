const cars = [
  {
    id: 1,
    ownerId: 3,
    brand: "Toyota",
    model: "Corolla",
    licensePlate: "A123BC",
    vin: "1234567890ABCDEFG",
    color: "Красный",
    year: 2020,
    mileage: 50000,
    photo: "https://i.ibb.co/XZkL9fg/2024-11-28-14-27-27.png",
    comment: "Автомобиль не заводится, требуется проверка аккумулятора.",
    serviceStatus: "В работе", 
  },
  {
    id: 2,
    ownerId: 4, 
    brand: "Ford",
    model: "Focus",
    licensePlate: "B456CD",
    vin: "0987654321HGFEDCBA",
    color: "Синий",
    year: 2025,
    mileage: 80000,
    photo: "https://i.ibb.co/dkpvK7v/2024-11-28-14-31-18.png",
    comment: "Требуется установка сигнализации.",
    serviceStatus: "Записан/ожидание согласования", 
  },
];

export default cars;