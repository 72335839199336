const API_BASE_URL = "https://jedi1150.com/api";

/**
 * Обработчик ошибок API.
 * @param {Response} response - Объект ответа fetch.
 * @throws {Error} - Если запрос не удался.
 */
const handleApiError = async (response) => {
  let errorMessage = `Ошибка: ${response.status}`;
  try {
    const errorData = await response.json();
    errorMessage = errorData.message || errorMessage;
  } catch {
    // Если тело ответа не в формате JSON
    errorMessage = response.statusText || errorMessage;
  }
  console.error("API Error:", errorMessage);
  throw new Error(errorMessage);
};

/**
 * Базовая функция для выполнения запросов.
 * @param {string} endpoint - Путь к API.
 * @param {Object} options - Настройки fetch.
 * @returns {Promise<any>} - Ответ сервера в формате JSON.
 */
const fetchWithAuth = async (endpoint, options = {}) => {
  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    credentials: "include", // Включаем cookies в запрос
    ...options,
  });
  if (!response.ok) {
    await handleApiError(response);
  }
  return response.json();
};

/**
 * Получение списка пользователей с поддержкой пагинации.
 */
export const fetchUsersWithPagination = async (page, size) => {
  try {
    const data = await fetchWithAuth(`/users?page=${page}&size=${size}`);
    return {
      users: data.content.map((user) => ({
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`.trim(),
        role: user.role || "Не указана",
      })),
      totalPages: data.totalPages,
      totalElements: data.totalElements,
    };
  } catch (error) {
    console.error("Ошибка загрузки пользователей:", error);
    throw error;
  }
};

/**
 * Получение списка автомобилей.
 */
export const fetchCars = async () => {
  try {
    const data = await fetchWithAuth("/cars");
    return data.map((car) => ({
      ...car,
      serviceStatus: car.serviceStatus || "Не указано",
    }));
  } catch (error) {
    console.error("Ошибка загрузки автомобилей:", error);
    throw error;
  }
};

/**
 * Поиск клиентов по параметру.
 */
export const searchClients = async (query, filter) => {
  try {
    const url = new URL(`/clients/search`, API_BASE_URL);
    url.searchParams.append("query", query);
    url.searchParams.append("filter", filter);
    return await fetchWithAuth(url.pathname + url.search);
  } catch (error) {
    console.error("Ошибка поиска клиентов:", error);
    throw error;
  }
};

/**
 * Поиск автомобилей по параметру.
 */
export const searchCars = async (query, filter) => {
  try {
    const url = new URL(`/cars/search`, API_BASE_URL);
    url.searchParams.append("query", query);
    url.searchParams.append("filter", filter);
    return await fetchWithAuth(url.pathname + url.search);
  } catch (error) {
    console.error("Ошибка поиска автомобилей:", error);
    throw error;
  }
};

/**
 * Создание нового пользователя.
 */
export const createUser = async ({ firstName, lastName, role, username, password }) => {
  try {
    return await fetchWithAuth("/users", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ firstName, lastName, role, username, password }),
    });
  } catch (error) {
    console.error("Ошибка создания пользователя:", error);
    throw error;
  }
};

/**
 * Получение списка заказов.
 */
export const fetchOrders = async (page, size) => {
  try {
    const response = await fetchWithAuth(`/orders?page=${page}&size=${size}`);
    return response;
  } catch (error) {
    console.error("Ошибка загрузки заказов:", error);
    throw error;
  }
};