import React, { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import cars from "../../mockData/cars";
import "./ClientPage.css";

const ClientPage = () => {
  const { user } = useContext(UserContext);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const car = cars.find((car) => car.ownerId === user?.id);

  if (!car) {
    return <p>Информация об автомобиле недоступна.</p>;
  }

  return (
    <div className="client-page-container">
      <h1>Информация о клиенте</h1>
      
      <div className="section">
        <h2>ФИО клиента</h2>
        <p>{user.fullName}</p>
      </div>

      <div className="section">
        <h2>Информация об автомобиле</h2>
        <p><strong>Марка:</strong> {car.brand}</p>
        <p><strong>Модель:</strong> {car.model}</p>
        <p><strong>Гос.номер:</strong> {car.licensePlate}</p>
        <p><strong>VIN номер:</strong> {car.vin}</p>
        <p><strong>Цвет кузова:</strong> {car.color}</p>
        <p><strong>Год выпуска:</strong> {car.year}</p>
        <p><strong>Пробег:</strong> {car.mileage} км</p>
      </div>

      <div className="section">
        <h2>Комментарий</h2>
        <p>{car.comment || "Комментарий отсутствует"}</p>
      </div>

      <div className="section">
        <h2>Фотографии автомобиля</h2>
        <div className="photo-gallery-grid">
          {[car.photo, car.photo, car.photo].map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt={`Фото автомобиля ${index + 1}`}
              onClick={() => setSelectedPhoto(photo)}
            />
          ))}
        </div>
      </div>

      {selectedPhoto && (
        <div className="photo-modal" onClick={() => setSelectedPhoto(null)}>
          <img src={selectedPhoto} alt="Увеличенное фото автомобиля" />
          <button className="photo-modal-close" onClick={() => setSelectedPhoto(null)}>
            ✕
          </button>
        </div>
      )}
    </div>
  );
};

export default ClientPage;